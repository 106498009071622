@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.nav {
    &__container {
        width: 100%;
        height: $navHeight;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        z-index: 100;

        &--dark {
            color: white;
            text-shadow: 1px 1px $mediumGrey;

            .nav__img {
                fill: white;
                text-shadow: 1px 1px $mediumGrey;
            }

            .nav__toggle-bar {
                background-color: white
            }
        }

        &--light {
            color: black;
            box-shadow: -1px 1px 5px grey;


            .nav__img {
                fill: black;
            }
        }
    }

    &__back {
        z-index: -1;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        background: white;
        opacity: 0;
        transition: opacity 0.5 ease;

        &--visible {
            opacity: 0.8;
            z-index: 90;
        }
    }

    &__logo {
        width: 50px;
        padding: 12px 25px;
        flex-shrink: 0;

        &--dark {
            fill: white
        }

        &--light {
            fill: black
        }
    }

    &__toggle {
        display: none;
        text-align: right;
        margin: 15px 30px 0 0;
        font-size: 2em;
        cursor: pointer;

        @include target-mobile {
            display: flex;
            align-items: center;
        }

        &--open {
            .nav__toggle-bar {
                &--one {
                    opacity: 0;
                }

                &--two {
                    transform: rotate(45deg);
                }

                &--three {
                    transform: translateY(-9px) rotate(-45deg);
                }
            }
        }

        &-bar {
            background-color: black;
            width: 30px;
            height: 3px;
            margin: 6px 0;
            transition: all 0.5s ease;
        }
    }

    &__links {
        margin: 30px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.2em;
        letter-spacing: 1.5px;

        @include target-mobile {
            position: absolute;
            top: 65px;
            right: 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            height: 0;
            overflow-y: hidden;
            margin: 0;

        }

        &--open {
            height: 200px;
            transition: height 0.5s ease;
            margin-top: 20px;
        }
    }

    &__link {
        margin-left: 20px;
        padding: 0 5px;

        @include target-mobile {
            margin-bottom: 15px;
        }

        &--active {
            border: 1px solid;
        }
    }

    &__active-route {
        font-size: 0.8em;
        margin-right: 15px;
    }
}