@import '../../shared/styles/variables.scss';
@import '../../shared/styles/mixins.scss';

.home {
    &__container {
        height: 100vh;
        min-height: 300px;
        user-select: none;
    }

    &__back {
        background-image: url('../../assets/home.jpg');
        height: 100%;
        filter: brightness(0.5);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__body {
        color: white;
        font-weight: 200;
        font-size: 3em;
        position: absolute;
        top: 40%;
        text-shadow: 1px 1px black;

        &-text {
            padding: 30px;

            &--sub {
                font-size: 0.8em;
            }
        }

        &-name-break {
            display: none;

            @include target-mobile {
                display: block;
            }
        }

        @include target-mobile {
            font-size: 2em;
        }
    }
}