@mixin target-mobile {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin target-small-device {
    @media only screen and (min-width: 768px) and (max-width: 1069px) {
        @content;
    }
}

@mixin target-medium-device {
    @media only screen and (min-width: 1070px) and (max-width: 1200px) {
        @content;
    }
}

@mixin target-large-device {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin target-mobile-and-small-device {
    @include target-mobile {
        @content;
    }

    @include target-small-device {
        @content;
    }
}

@mixin target-medium-and-large-device {
    @include target-medium-device {
        @content;
    }

    @include target-large-device {
        @content;
    }
}

@mixin target-ios {
    @supports (-webkit-touch-callout: none) {
        @content;
    }
}

@mixin target-ipad-portrait {
    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
        @content;
    }
}

@mixin target-ie-and-edge {
    @include target-ie {
        @content;
    }

    @include target-edge {
        @content;
    }
}

@mixin target-firefox {
    @supports (-moz-appearance: none) {
        @content;
    }
}

@mixin target-ie {

    /* all IE versions <= 11 */
    @media screen and (-ms-high-contrast: none) {
        @content;
    }
}

@mixin target-edge {

    /* all edge versions */
    @supports (-ms-ime-align: auto) {
        @content;
    }
}