@import '../../shared/styles/variables.scss';
@import '../../shared/styles/mixins.scss';

.experience {
    &__container {
        height: calc(100vh - #{$navHeight});
        top: $navHeight;
        position: absolute;
        overflow: auto;
        padding: 0 20px 40px;
    }

    &__body-header {
        color: $blue;
        text-align: center;
        margin: 50px 0 30px 0;
    }

    &__projects {
        display: flex;
        flex-wrap: wrap;
    }

    &__responsibilities {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        >span {
            margin: 10px;
        }
    }

    &__project {
        max-width: calc((100% - 60px)/3);
        flex-grow: 1;
        background-color: $lightGrey;
        margin: 10px;
        padding: 15px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        box-shadow: 1px 1px 4px #aaa;

        @include target-mobile {
            max-width: none;
        }

        @include target-small-device {
            max-width: calc((100% - 60px)/2);
        }

        ul {
            flex-grow: 2;
            list-style-type: '\2713\0020';

            li {
                margin-bottom: 8px;

                &::marker {
                    color: rgb(9, 163, 9);
                }
            }
        }

        h3 {
            height: 30px;
        }
    }

    &__stack {
        display: flex;
        flex-flow: wrap;

        &-pill {
            margin: 2px;
            background: $blue;
            color: white;
            border-radius: 12px;
            padding: 0px 6px;
            line-height: 24px;

        }
    }
}