@import '../../shared/styles/variables.scss';

.resume {
    &__container {
        height: calc(100vh - #{$navHeight});
        margin-top: $navHeight;
    }

    &__download {
        padding: 30px;
        color: $blue;
        font-weight: bold;
        text-align: center;
    }
}