@import '../../shared/styles/variables.scss';
@import '../../shared/styles/mixins.scss';

.about {
    &__container {
        height: calc(100vh - #{$navHeight});
        margin-top: $navHeight;
        overflow: auto;
    }

    &__header {
        display: flex;
        align-items: center;

        @include target-mobile {
            flex-direction: column;
        }
    }

    &__body {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 30px 100px;
        font-size: 1.5em;

        @include target-mobile {
            padding: 30px;
            font-size: 1em;
            flex-direction: column;
        }
    }

    &__text {
        max-width: $maxLongTextWidth;

        a {
            color: $blue;
            font-weight: bold;
        }
    }

    &__image {
        flex-shrink: 0;
        margin: 32px 50px 0 0;
        width: 200px;
        height: 200px;
        background-image: url(../../assets/kp.jpg);
        background-size: cover;
        border-radius: 50%;

        @include target-mobile {
            margin: 0 0 20px 0;
        }
    }
}